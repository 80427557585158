.EntityAlbum {

}

.Description {
    max-height: 3em;
    overflow-y: hidden;
}

.Involvement {

}

.Genres {

}