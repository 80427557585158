.Select {
    /* Make it perfectly round! */
    height: 48px;
    border-radius: 44px;
    padding: 8px;

    /* Colors... */
    border: 2px solid #ff8500;
    background-color: white;
    color: #5F860A;

    font-size: large;
}

.Select:hover {
    background-color: rgba(255, 133, 0, 0.2);
}

.Select:focus {
    /* Make a custom focus outline */
    outline: none;
    border-width: 4px;
    padding: 6px;
}

.Select:active {
    background-color: rgba(255, 133, 0, 0.5);
}

.Select[disabled] {
    background-color: #cccccc !important;
    border-color: #666666 !important;
    color: #666666 !important;
    cursor: not-allowed;
}
