.layout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;

    min-height: calc(100vh - 64px);

    padding: 8px;
}