.Button {
    /* Make it perfectly round! */
    border-radius: 9999px;
    padding: 0;

    /* Colors... */
    border: 2px solid #ff8500;
    background-color: white;
    color: #5F860A;

    /* And a nice clickable cursor! */
    cursor: pointer;
}

.Button:active {
    background-color: rgba(255, 133, 0, 0.5);
}

.Button:hover {
    background-color: rgba(255, 133, 0, 0.2);
}

.Button:focus {
    /* Make a custom focus outline */
    outline: none;
    border-width: 4px;
}

.Button[disabled] {
    background-color: #cccccc !important;
    border-color: #666666 !important;
    color: #666666 !important;
    cursor: not-allowed;
}
