.searchBlock {
    display: grid;
    grid-template-columns: 100px 1fr auto auto;
    grid-gap: 8px;
    width: 100%;
    max-width: 638px;
}

.Select {
    grid-column: 1;
    grid-row: 1;
}

.Input {
    grid-column: 2;
    grid-row: 1;
    flex-grow: 1;
}

.Search {
    grid-column: 3;
    grid-row: 1;
}

.Settings {
    grid-column: 4;
    grid-row: 1;
}

.Advanced {
    grid-column: 1 / 5;
    grid-row: 2;
}

.AdvancedShow {
    display: block;
}

.AdvancedHide {
    display: none;
}