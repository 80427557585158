* {
    box-sizing: border-box;
}

body {
    margin: 0;
    color: #ff8500;
    background-color: white;
    font-family: sans-serif;
}

a {
    color: inherit;
}

.halfparent {
    opacity: 0.5;
}
