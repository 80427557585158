.Entity {
    display: grid;
    grid-template-columns: auto 1fr 1fr;
    grid-template-rows: auto 1fr;
    grid-column-gap: 8px;
    grid-template-areas:
            "a b b"
            "a c d";
    margin-top: 16px;
    margin-bottom: 16px;
}

.Button {
    grid-area: a;
}

.Title {
    grid-area: b;
    font-weight: bold;
    font-size: large;
}

.Contents {
    grid-area: c;
    margin-top: 4px;
    margin-bottom: 4px;
}

.Fields {
    grid-area: d;
}