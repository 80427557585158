.EntitySong {

}

.Description {
    margin-bottom: 4px;
    max-height: 3em;
    overflow-y: hidden;
}

.Lyrics {
    font-style: italic;
    margin-top: 4px;
    max-height: 3em;
    overflow-y: hidden;
    max-width: 482px;
    overflow-x: hidden;
}

.Involvement {

}

.Album {

}

.Genres {

}