.EntityGenre {

}

.Description {
    max-height: 3em;
    overflow-y: hidden;
}

.Supergenre {

}

.Subgenre {

}