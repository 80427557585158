.EntitySong {

}

.Description {
    max-height: 3em;
    overflow-y: hidden;
}

.Song {

}
