.layout {
    display: grid;
    grid-template-columns: 180px auto;
    grid-template-rows: auto 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 24px;

    min-height: calc(100vh - 64px);

    padding: 8px;
}

@media screen and (max-width: 899px) {
    .layout {
        grid-template-areas:
            "a b"
            "d d";
    }
}

@media screen and (min-width: 900px) {
    .layout {
        grid-template-areas:
            "a b"
            "c d";
    }
}

.Logo {
    grid-area: a;
}

.SearchBar {
    grid-area: b;
}

.Results {
    grid-area: d;
}