.AdvancedSettings {
    border: 2px solid #ff8500;
    border-radius: 22px;
    padding: 16px;
}

.Tip {
    margin: 6px 0;
    font-size: small;
}

.BoxTitle {
    font-size: x-large;
    margin-bottom: 8px;
}